export const BlocksToJSON = (blocks) => {
  let json = new Object();
  for (let i = 0; i < blocks.length; i++) {
    json[i] = (blocks[i]);
  }
  return JSON.stringify(json);
}
export const JSONToBlock = (json_raw) => {
  let blocks = [];
  const json = JSON.parse(json_raw);
  const len = Object.keys(json).length;
  for (let i = 0; i < len; i++) {
    const element = json[i];
    blocks.push(element);
  }
  return blocks;
}