import React from "react";
import SoundIcon from "../images/volume-high.png"
import { useState, useEffect } from "react";

export const Accent = ({index, accentValue, UpdateAccent}) => {

  const iconSize = '25px';
  let accentInversionFirst = '100';
  let accentBackgroundFirst = '#ffffff';
 
  const [accentInversion, setAccentInversion] = useState(accentInversionFirst);
  const [accentBackground, setAccentBackground] = useState(accentBackgroundFirst);

  const setAccentIcon = (accent) => {
    switch (accent) {
      case 0:
        setAccentBackground('#ffffff');
        setAccentInversion('20');
        break;
      case 1:
        setAccentBackground('#ffffff');
        setAccentInversion('100');
        break;
      case 2:
        setAccentBackground('#ffaa01');
        setAccentInversion('100');
        break;
    }
  }


  const AccentHandler = () => {
    UpdateAccent(index);
  }
  useEffect(() => {
    setAccentIcon(accentValue);
  })

  return (
    <div style={{ cursor: 'pointer', border: 'solid 1px', width: iconSize, height: iconSize, borderRadius: '50%', padding: '5px', textAlign: 'center', background: accentBackground, margin: '5px' }} onClick={AccentHandler}>
      <img alt="sound" src={SoundIcon} style={{ filter: 'invert(' + accentInversion + '%)', width: iconSize, height: iconSize }}></img>
    </div>
  )
}

export const Dead = () => {
  const iconSize = '25px';
  return (
    <div style={{ cursor: 'pointer', border: 'solid 1px #ffffff', width: iconSize, height: iconSize, borderRadius: '50%', padding: '5px', background: '#ffffff', margin: '5px', textAlign: 'center', display: 'block' }}>
      <div style={{ background: '#000000', width: '10px', height: '3px', margin: 'auto', display: 'inline-block' }}></div>
    </div>)
}