import React from "react";

export const Headline = () => {
  return (
    <div style={{margin: '20px'}}>
      <div style={{display: 'flex', justifyContent:'center'}}>
        <span style={{fontSize: '30px', fontWeight: 'bold'}}>Metronome Builder</span>
      </div>
      <div style={{display: 'flex', justifyContent:'center', marginBottom: '50px'}}>
        <span style={{fontSize: '20px', paddingLeft: '230px'}}>for musicians.</span>
      </div>
    </div>
  )
}