import React from "react";
import { useState } from "react";
export const Info = () => {

  let showHide = "Hide";
  let switchVisibility = "visible";
  let switchDisplay = "block";

  const [isShowHide, setShowHide] = useState(showHide);
  const [isSwitchVisibility, setSwitchVisibility] = useState(switchVisibility);
  const [isSwitchDisplay, setSwitchDisplay] = useState(switchDisplay);

  const showHideInfo = () => {
    if (isShowHide === "Hide") {
      setShowHide("Show");
      setSwitchVisibility("hidden");
      setSwitchDisplay("none");
    } else {
      setShowHide("Hide");
      setSwitchVisibility("visible");
      setSwitchDisplay("block");
    }
  }
  return (
    <div style={{ width: '50%', margin: '0 auto' }}>
      <div style={{ border: '2px solid', borderRadius: '5px' }}>
        <div style={{backgroundColor: '#000000', padding: '5px'}}>
          <span style={{color: '#ffffff', fontWeight: 'bold', fontSize: '16px'}}>About</span>
          <span style={{color: '#ffffff', fontSize: '16px', float: 'right', cursor: 'pointer'}} onClick={showHideInfo}>{isShowHide}</span>
        </div>
        <div style={{ padding: '20px', visibility: isSwitchVisibility, display: isSwitchDisplay}}>
          <p>Metronome Builder is a website for musicians to build their own custom metronomes for a specific purpose and need. Metronome Builder allows to build a whole metronome audio file out of a combination of different musical parameters. <br /><br /> It supports: </p>

          <ul>
            <li>Time signatures</li>
            <li>BPM</li>
            <li>Subdivisons</li>
            <li>Accents</li>
            <li>Different Audio formats</li>
          </ul>
        </div>
      </div>
    </div>
  )
}