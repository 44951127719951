import React from "react";
import { useState } from "react";
import SoundIcon from "../images/volume-high.png"
import { Accent } from "./Accent";
export const Explanation = () => {

  let showHide = "Hide";
  let switchVisibility = "visible";
  let switchDisplay = "block";

  const [isShowHide, setShowHide] = useState(showHide);
  const [isSwitchVisibility, setSwitchVisibility] = useState(switchVisibility);
  const [isSwitchDisplay, setSwitchDisplay] = useState(switchDisplay);

  const showHideInfo = () => {
    if (isShowHide === "Hide") {
      setShowHide("Show");
      setSwitchVisibility("hidden");
      setSwitchDisplay("none");
    } else {
      setShowHide("Hide");
      setSwitchVisibility("visible");
      setSwitchDisplay("block");
    }
  }
  const iconSize = '25px';
  return (
    <div style={{ width: '50%', margin: '0 auto' }}>
      <div style={{ border: '2px solid', borderRadius: '5px' }}>
        <div style={{backgroundColor: '#000000', padding: '5px'}}>
          <span style={{color: '#ffffff', fontWeight: 'bold', fontSize: '16px'}}>Help</span>
          <span style={{color: '#ffffff', fontSize: '16px', float: 'right', cursor: 'pointer'}} onClick={showHideInfo}>{isShowHide}</span>
        </div>
        <div style={{ padding: '20px', visibility: isSwitchVisibility, display: isSwitchDisplay}}>
          <b>Add Block</b>
          <br></br>
          <span>This button allows you to add different parts to your metronome. In a block you can individually set the BPM, time signature, subdivisions, accents and repetitions.</span>

            <div style={{marginTop: '20px', marginLeft: '10px'}}>
                <b>BPM</b>
                <br></br>
                <span>Sets the beats per minutes.</span>

                <br></br>
                <br></br>
                <b>Time Signature</b>
                <br></br>
                <span>Sets the time signature by changing the upper and lower value of the bar.</span>

                <br></br>
                <br></br>
                <b>Subdivision</b>
                <br></br>
                <span>Sets the subdivision of the bar according to time signature.</span>

                <br></br>
                <br></br>
                <b>Accents</b>
                <br></br>
                <span>Sets individual accents to every note of the bar and subdivision.</span>
                <br></br>
                <br></br>

                <table>
                    <tr>
                        <td>
                        <div style={{ cursor: 'pointer', border: 'solid 1px', width: iconSize, height: iconSize, borderRadius: '50%', padding: '5px', textAlign: 'center', background: '#ffffff', margin: '5px' }}>
                            <img alt="sound" src={SoundIcon} style={{ filter: 'invert(' + '20' + '%)', width: iconSize, height: iconSize }}></img>
                        </div>
                        </td>
                        <td><b>Muted</b></td>
                    </tr>
                    <tr>
                        <td>
                        <div style={{ cursor: 'pointer', border: 'solid 1px', width: iconSize, height: iconSize, borderRadius: '50%', padding: '5px', textAlign: 'center', background: '#ffffff', margin: '5px' }}>
                            <img alt="sound" src={SoundIcon} style={{ filter: 'invert(' + '100' + '%)', width: iconSize, height: iconSize }}></img>
                        </div>
                        </td>
                        <td><b>Click</b></td>
                    </tr>
                    <tr>
                        <td>
                        <div style={{ cursor: 'pointer', border: 'solid 1px', width: iconSize, height: iconSize, borderRadius: '50%', padding: '5px', textAlign: 'center', background: '#ffaa01', margin: '5px' }}>
                            <img alt="sound" src={SoundIcon} style={{ filter: 'invert(' + '100' + '%)', width: iconSize, height: iconSize }}></img>
                        </div>
                        </td>
                        <td><b>Accent</b></td>
                    </tr>
                </table>

                <br></br>
                <b>Repetition</b>
                <br></br>
                <span>Sets the number of repetitions of the current block.</span>

            </div>

        </div>
      </div>
    </div>
  )
}