import { Headline } from "./components/Headline";
import { Info } from "./components/Info";
import Select from "react-select";
import { useState } from "react";
import { Block } from "./components/Block";
import { BlocksToJSON, JSONToBlock } from "./JsonConverter";
import { Explanation } from "./components/Explanation";
import $ from 'jquery'; 
function App() {

  const button_style = { background: '#000000', color: '#ffffff', cursor: 'pointer', fontSize: '14px', textAlign: 'center', borderRadius: '5px', padding: '10px', fontWeight: 'bold', textDecoration: 'none', borderStyle: 'none', outlineStyle: 'none' };

  const templateBlock = {
    amount: 1,
    bpm: 100,
    bar_up: 4,
    bar_down: 4,
    subdivision: 8,
    accents: [2, 1, 2, 1, 2, 1, 2, 1]
  };
  const [MetronomeDataBlocks, setMetronomeDataBlocks] = useState([]);
  const [UniqueBlockIDs, setUniqueBlockIDs] = useState([]);

  const generateKey = () => {
    let length = 16;
    var result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if (!(UniqueBlockIDs.includes(result))) {
      return result;
    }
    return result;
  }


  const UpdateBlock = (index, data) => {
    let newMetronomeDataBlocks = [...MetronomeDataBlocks];
    if (data.amount != null) {
      newMetronomeDataBlocks[index].amount = data.amount;
    }
    if (data.bpm != null) {
      newMetronomeDataBlocks[index].bpm = data.bpm;
    }
    if (data.bar_up != null) {
      newMetronomeDataBlocks[index].bar_up = data.bar_up;
    }
    if (data.bar_down != null) {
      newMetronomeDataBlocks[index].bar_down = data.bar_down;
    }
    if (data.subdivision != null) {
      newMetronomeDataBlocks[index].subdivision = data.subdivision;
    }
    if (data.accents != null) {
      newMetronomeDataBlocks[index].accents = data.accents;
    }
    setMetronomeDataBlocks(newMetronomeDataBlocks);
  }

  const AddBlockHandler = () => {
    const newKey = generateKey();
    let newUniqueBlockIDs = [...UniqueBlockIDs];
    newUniqueBlockIDs.push(newKey);
    setUniqueBlockIDs(newUniqueBlockIDs);

    let newMetronomeDataBlocks = [...MetronomeDataBlocks];
    newMetronomeDataBlocks.push(templateBlock);
    setMetronomeDataBlocks(newMetronomeDataBlocks);
  }

  const MoveBlock = (index, direction) => {
    let swap_index;
    if (direction === "up") {
      if (index === 0) {
        return;
      }
      swap_index = index - 1;
    }
    if (direction === "down") {
      if (index === MetronomeDataBlocks.length - 1) {
        return;
      }
      swap_index = index + 1;
    }

    let newUniqueBlockIDs = [...UniqueBlockIDs];
    const c = newUniqueBlockIDs[index];
    newUniqueBlockIDs[index] = newUniqueBlockIDs[swap_index];
    newUniqueBlockIDs[swap_index] = c;
    setUniqueBlockIDs(newUniqueBlockIDs);

    let newMetronomeDataBlocks = [...MetronomeDataBlocks];
    const tmp = newMetronomeDataBlocks[index];
    newMetronomeDataBlocks[index] = newMetronomeDataBlocks[swap_index];
    newMetronomeDataBlocks[swap_index] = tmp;
    setMetronomeDataBlocks(newMetronomeDataBlocks);

  }

  const DeleteBlock = (index) => {
    let newMetronomeDataBlocks = [];
    let newUniqueBlockIDs = [];
    for (let i = 0; i < MetronomeDataBlocks.length; i++) {
      if (i === index) {
        continue;  
      }
      newMetronomeDataBlocks.push(MetronomeDataBlocks[i]);
      newUniqueBlockIDs.push(UniqueBlockIDs[i]);
    }
    setMetronomeDataBlocks(newMetronomeDataBlocks);
    setUniqueBlockIDs(newUniqueBlockIDs);
  }

  const DownloadOptions = [
    { value: 'wav', label: 'WAV' },
    { value: 'mp3', label: 'Mp3' },
    { value: 'm4a', label: 'M4a' }
  ];
  const [DownloadOptionsValue, setDownloadOptionsValue] = useState(DownloadOptions[0]);
  const DownloadOptionsHandler = (event) => {
    setDownloadOptionsValue(event);
  }
  const DownloadFileHandler = () => {
    const format = DownloadOptionsValue.value;
    $.ajax({
      url: "handler/build.php?json=" + JSON.stringify(BlocksToJSON(MetronomeDataBlocks)) + "&format=" + format,
      type: 'GET',
      dataType: 'json',
      complete: function (res) {
        if (res.status == 200) {
          window.location="handler/download.php?format=" + format;
        }
      }
    });

  }

  const fillFormatZero = (timestring) => {
    if (timestring.length === 2) {
      return timestring;
    }
    if (timestring.length === 1) {
      return "0" + timestring;
    }
  
  }

  const fillFormatMilliZero = (timestring) => {
    let out = "";
    let timelen = timestring.length;
    for (let i = timelen; i < 3; i++) {
      out += "0";
    }
    return out + timestring;
  }

   const timeFormat = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time - minutes * 60);
    const milliseconds = Math.round((time - minutes * 60 - seconds) * 1000);

    const minutes_string = minutes.toString();
    const seconds_string = seconds.toString();
    const milliseconds_string = milliseconds.toString();

    return fillFormatZero(minutes_string) + ":" + fillFormatZero(seconds_string) + "." + fillFormatMilliZero(milliseconds_string);
    
   }

  const calculateDuration = () => {

    let len = 0;
    for (let i = 0; i < MetronomeDataBlocks.length; i++) {
      const block = MetronomeDataBlocks[i];
      if (block.bpm === 0) {
        continue;
      }
      const block_len = 60 / block.bpm * block.bar_up * block.amount;
      len += block_len;
    }
    return len;
  }


  const ImportSettingsHandler = () => {
    let element = document.getElementById("file-input");
    element.click();
  }
  const ImportLoadFile = (event) => {
    var fr = new FileReader();
    let newMetronomeBlocks;
    let newUniqueBlockIDs = [];
    fr.onload = function() {
      newMetronomeBlocks = (JSONToBlock(fr.result));
      for (let i = 0; i < newMetronomeBlocks.length; i++) {
        newUniqueBlockIDs.push(generateKey());
      }
      setUniqueBlockIDs(newUniqueBlockIDs);
      setMetronomeDataBlocks(newMetronomeBlocks);
    };

    fr.readAsText(event.target.files[0]);

  }
  const ExportSettingsHandler = () => {
    const filename = ("metronomebuilder-settings.json");
    var element = document.createElement('a');
    let jsonData = BlocksToJSON(MetronomeDataBlocks);

    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonData));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }


  return (
    <div>
      <Headline />
      <Info />
      <div style={{height: '25px'}}></div>
      <Explanation />
      <div style={{ height: '25px' }}></div>
      <div style={{ width: '50%', margin: '0 auto' }}>
        <div style={{ border: '2px solid', borderRadius: '5px' }}>
          <div style={{ backgroundColor: '#000000', padding: '5px' }}>
            <span style={{ color: '#ffffff', fontWeight: 'bold', fontSize: '16px' }}>Menu</span>
          </div>
          <div style={{ padding: '20px' }}>
            <div style={{ display: 'flex' }}>
              <div>
                <span style={{ fontWeight: 'bold' }}>Builder</span>
                <div style={{ height: '6px' }}></div>
                <span>Metronome Length</span>
                <div style={{ height: '1 px' }}></div>
                <span>{timeFormat(calculateDuration())}</span>

                <div style={{ marginTop: '6px' }}>
                  <button style={button_style} onClick={AddBlockHandler}>Add Block</button>
                </div>
              </div>
              <div style={{ width: '1px', height: '100px', background: '#000000', marginRight: '20px', marginLeft: '20px' }}></div>
              <div style={{ alignItems: 'center' }}>
                <span style={{ fontWeight: 'bold' }}>Settings</span>
                <div style={{ height: '6px' }}></div>
                <button style={button_style} onClick={ImportSettingsHandler}>Import Settings</button>
                <input id="file-input" type="file" name="name" style={{display: 'none'}} onChange={ImportLoadFile}/>
                <div style={{ height: '10px' }}></div>
                <button style={button_style} onClick={ExportSettingsHandler}>Export Settings</button>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '1px', height: '100px', background: '#000000', marginRight: '20px', marginLeft: '20px' }}></div>
                <div style={{ width: '150px' }}>
                  <span style={{ fontWeight: 'bold' }}>Audio Format</span>
                  <div style={{ height: '6px' }}></div>
                  <Select
                    value={DownloadOptionsValue}
                    options={DownloadOptions}
                    onChange={DownloadOptionsHandler}
                  />
                  <div style={{ marginTop: '7px' }}>
                    <button style={button_style} onClick={DownloadFileHandler}>Download</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: '50px' }}></div>
      {
        MetronomeDataBlocks.map((block, index) => {
          return ( 
            <Block 
              key = {UniqueBlockIDs[index]}
              index = {index}
              block = {block}
              UpdateBlock = {UpdateBlock}
              MoveBlock = {MoveBlock}
              DeleteBlock = {DeleteBlock}
            />
          )
        })
      }
    </div>
  );
}

export default App;
