import React from "react";
import Select from "react-select";
import arrow_image from '../images/arrow.png'
import delete_image from '../images/delete.png';
import { Accent, Dead } from "./Accent";
import { useState } from "react";
export const Block = ({ index, block, UpdateBlock, MoveBlock, DeleteBlock }) => {
  const amount = block.amount;
  const bpm = block.bpm;
  const bar_up = block.bar_up;
  const bar_down = block.bar_down;
  const subdivision = block.subdivision;
  const accents = block.accents;

  var numberSubdivisionString = {};
  numberSubdivisionString[4] = "Quarter";
  numberSubdivisionString[8] = "Eighth";
  numberSubdivisionString[12] = "Triplet";
  numberSubdivisionString[16] = "Sixteenth";
  numberSubdivisionString[24] = "Sixtuplet";
  numberSubdivisionString[32] = "Thirtysecond";

  const BarOptions = [
    { value: 4, label: '4' },
    { value: 8, label: '8' },
    { value: 16, label: '16' },
    { value: 32, label: '32' }
  ];
  let SubdivisonOptionsDefault = [
    { value: 4, label: numberSubdivisionString[4] },
    { value: 8, label: numberSubdivisionString[8] },
    { value: 12, label: numberSubdivisionString[12] },
    { value: 16, label: numberSubdivisionString[16] },
    { value: 24, label: numberSubdivisionString[24] },
    { value: 32, label: numberSubdivisionString[32] }
  ];
  
  const subdivisionByBarOptions = (time) => {
    if (time === 4) {
      return [4, 8, 12, 16, 24, 32];
    }
    if (time === 8) {
      return [8, 16, 24 ,32];
    }
    if (time === 16) {
      return [16, 32];
    }
    if (time === 32) {
      return [32];
    }
  }


  const allowedSubdivisionsBuilder = (time) => {
    let subdivs = subdivisionByBarOptions(time);
    let newSubdivisionOptions = [];
    for (let i = 0; i < SubdivisonOptionsDefault.length; i++) {
      let element = SubdivisonOptionsDefault[i].value;
      if (subdivs.includes(element)) {
        newSubdivisionOptions.push(SubdivisonOptionsDefault[i]);
      }
    }
    
    return newSubdivisionOptions;
  }
  const [SubdivisonOptions, setSubdivionOptions] = useState(allowedSubdivisionsBuilder(bar_down));


  const isNumeric = (value) => {
    return /^\d+$/.test(value);
  }

  const MoveUpHandler = () => {
    MoveBlock(index, "up");
  }
  const MoveDownHandler = () => {
    MoveBlock(index, "down");
  }
  const DeleteHandler = () => {
    DeleteBlock(index);
  }
  const BpmHandler = (event) => {
    let value = event.target.value;
    if (isNumeric(value)) {
      value = parseInt(value);
      if (value < 350) {
        UpdateBlock(index, {bpm: value});
      }
    }
  }
  const BarUpHandler = (event) => {
    let value = event.target.value;
    if (isNumeric(value)) {
      value = parseInt(value);
      if (value < 1000) {
        UpdateBlock(index, {bar_up: value});
        AccentChanger(value, bar_down, subdivision);
      }
    }
  }
  const BarOptionsHandler = (event) => {
    UpdateBlock(index, {bar_down: event.value});
    let new_subopts = allowedSubdivisionsBuilder(event.value);
    if (!subdivisionByBarOptions(event.value).includes(subdivision)) { 
      UpdateBlock(index, {subdivision: (new_subopts[0].value)});
      AccentChanger(bar_up, event.value, (new_subopts[0].value));
    } else {
      AccentChanger(bar_up, event.value, subdivision);
    }
    setSubdivionOptions(allowedSubdivisionsBuilder(event.value));

    
  }
  const SubdivisionOptionsHandler = (event) => {
    UpdateBlock(index, {subdivision: event.value});
    AccentChanger(bar_up, bar_down, event.value);
  }
  const RepetitionHandler = (event) => {
    let value = event.target.value;
    if (isNumeric(value)) {
      value = parseInt(value);
      if (value < 1000) {
        UpdateBlock(index, {amount: value});
      }
    }
  }
  const AccentChanger = (bar_up_new, bar_down_new, subdivision_new) => {
    const accentLen = accents.length;
    const barLen =  bar_up_new / bar_down_new * subdivision_new;
    if (accentLen === barLen) {
      return;
    }
    let newAccents = [];
    for (let i = 0; i < barLen; i++) {
      if (i % (subdivision_new / bar_down_new) === 0) {
        newAccents.push(2);
        continue;
      }
      newAccents.push(1);
    }
    UpdateBlock(index, {accents: newAccents});
  }

  const UpdateAccent = (accent_index) => {
    let newAccents = [...accents];
    newAccents[accent_index] = (accents[accent_index] + 1) % 3;
    UpdateBlock(index, {accents: newAccents});
  }

  const AccentListBuilder = () => {

    let accentList = [];
    for (let index = 0; index < accents.length; index++) {
      if (index !== 0 && index % (subdivision / bar_down) === 0) {
        accentList.push(<Dead
                          key = {-index}/>);
      }
      accentList.push(
        <Accent
          key={index}
          index={index}
          accentValue={accents[index]}
          UpdateAccent={UpdateAccent}
        />
      )
    }
    return accentList;
  }

  return (
    <div style={{ border: 'solid 1px', borderRadius: '5px', padding: '10px', margin: '0 auto', width: '70%', marginBottom: '5px' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ height: '100%', display: 'grid', width: '30px' }}>
          <img alt="up" src={arrow_image} style={{ width: '25px', cursor: 'pointer', margin: '0 auto' }} onClick={MoveUpHandler}></img>
          <span style={{ fontWeight: 'bold', margin: ' 0 auto', fontSize: '22px' }}>{index + 1}</span>
          <img alt="down" src={arrow_image} style={{ width: '25px', cursor: 'pointer', margin: ' 0 auto', WebkitTransform: 'rotate(180deg)' }} onClick={MoveDownHandler}></img>

          <img alt="remove" src={delete_image} style={{ width: '30px', cursor: 'pointer', margin: ' 0 auto', marginTop: '20px' }} onClick={DeleteHandler}></img>

        </div>
        <div style={{ background: '#000000', width: '1px', height: '100px', marginLeft: '10px', marginRight: '10px' }}></div>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontWeight: 'bold', marginRight: '10px' }}>BPM:</span>
            <input style={{ width: '24px', marginRight: '20px', height: '32px' }} defaultValue={bpm} onChange={BpmHandler} />

            <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Time Signature:</span>
            <input style={{ width: '17px', height: '32px' }} onChange={BarUpHandler} defaultValue={bar_up} />
            <span style={{ fontWeight: 'bold', marginLeft: '10px', marginRight: '10px' }}>/</span>
            <div style={{ width: '100px', marginRight: '20px' }}>
              <Select
                value={{ value: bar_down, label: bar_down}}
                options={BarOptions}
                onChange={BarOptionsHandler}
              />
            </div>
            <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Subdivision:</span>
            <div style={{ width: '160px' }}>
              <Select
                value={{ value: subdivision, label: numberSubdivisionString[subdivision] }}
                options={SubdivisonOptions}
                onChange={SubdivisionOptionsHandler}
              />
            </div>
            <span style={{ fontWeight: 'bold', marginRight: '10px', marginLeft: '20px' }}>Repetitions:</span>
            <input style={{ width: '24px', height: '32px' }} onChange={RepetitionHandler} defaultValue={amount} />

          </div>
          <div style={{ height: '10px' }}></div>
          <span style={{ fontWeight: 'bold' }}>Accents</span>
          <div style={{ height: '10px' }}></div>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>

            {
              AccentListBuilder()
            }

          </div>
        </div>
      </div>
    </div>
  )
}
